import React from 'react';
import Layout from '../../components/layout';
import { Helmet } from 'react-helmet';

import EducatorSectionOne from '../../components/bio/GradiantImage';
import SectionOneBackground from '../../images/educator_sec1.png';
import SectionOneMobileBackground from '../../images/mobile/Educator.png';

import EducatorSectionTwo from '../../components/bio/educator/EducatorSectionTwo';

import EducatorSectionThree from '../../components/bio/SplitImageOrangeBG';
import SectionThreeLeftImage from '../../images/educator_sec3_1_2.png';
import SectionThreeRightImage from '../../images/educator_sec3_2_2.png';

import EducatorSectionFour from '../../components/bio/BottomSectionContainer';
import SectionFourImage from '../../images/educator_sec4.png';

const Educator = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Armstrong's life and legacy: Educator</title>
        <script>
        {`
          (function (s, e, n, d, er) {
            s['Sender'] = er;
            s[er] = s[er] || function () {
              (s[er].q = s[er].q || []).push(arguments)
            }, s[er].l = 1 * new Date();
            var a = e.createElement(n),
                m = e.getElementsByTagName(n)[0];
            a.async = 1;
            a.src = d;
            m.parentNode.insertBefore(a, m)
          })(window, document, 'script', 'https://cdn.sender.net/accounts_resources/universal.js', 'sender');
          sender('a78ebf1b330821')
        `}
      </script>
      </Helmet>
      <Layout stacked={true}>
        <EducatorSectionOne
          background={SectionOneBackground}
          title={'Educator'}
          text={'Bill Armstrong spent his last decade as President of Colorado Christian University, which he thought was the “great calling,” and most important work of his life. The push for intellectual curiosity was a key to the mission of CCU under his leadership. He wanted it to be a world-class institution that, above all else, teaches students to think for themselves. He was proud to head a university that offers 23 undergraduate degrees and numerous graduate degrees, and sends missionaries around the world, as well as athletes to NCAA championships and debate teams to national victories.'}
          mobileBackground={SectionOneMobileBackground}
        />
        <EducatorSectionTwo />
        <EducatorSectionThree
          leftImage={SectionThreeLeftImage}
          rightImage={SectionThreeRightImage}
          leftCaptionWhite={'Speaking at the Values Aligned Leadership Summit, '}
          leftCaptionBlack={'2013'}
          rightCaptionWhite={'Transforming the Campus of CCU in Lakewood, '}
          rightCaptionBlack={'Colorado'}
        />
        <EducatorSectionFour
          image={SectionFourImage}
          blackCaption={'Running for reelection, '}
          orangeCaption={'1984'}
          blackLink={'Click to next page: '}
          orangeLink={'About Senator Bill Armstrong'}
          route="/bio/about"
        />
      </Layout>
    </>
  )
}

export default Educator;