import React from 'react';
import styled from 'styled-components';
import { useViewport } from '../../../hooks/useViewport';
import MobileContainer from '../ImageLeftWhiteBGContainer';

import image from '../../../images/educator_sec2.png';

const Container = styled.div`
  display: grid;
  grid-template-columns: 39.1vw 28.8vw 32.1vw;
  grid-template-rows: 100%;
  height: 100vh;
  margin-left: -72px;

  .left {
    grid-column: 1;
    margin-top: 12.8vh;
    padding-left: 106px;

    img {
      width: 447px;
    }

    .caption {
      font-family: 'Lato';
      font-size: 20px;
      line-height: 24px;
    }
  }

  .middle {
    grid-column: 2;
    font-family: 'Lato';
    font-size: 1.2vw;
    color: #212028;
    margin-top: 21.4vh;
    max-width: 20.7vw;
    justify-self: center;
  }

  .right {
    grid-column: 3;
    font-family: 'Lato';
    font-size: 1.2vw;
    max-width: 20.7vw;
    margin-top: 21.4vh;
  }
`;

const ChristianSectionTwo = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  return (
    width <= breakpoint ? (
      <MobileContainer
        captionBlack={"Speaking at the Western Conservative Summit"}
        text={'He taught openness and tolerance as a cornerstone of faith, believed Christians should laugh and have fun, and said that institutions like CCU would be great if they inspired joy. That would attract more students, better faculty, and dedicated donors, because most people want to be where the fun is. The strategy worked, and CCU during his tenure achieved higher academic standards, a stronger curriculum, a campus transformed with new buildings, and a generation of young people inspired by his faith and his example. Enrollment more than doubled, and the American Council of Trustees and Alumni has now ranked CCU in the top two percent of colleges nationally for its core curriculum eight consecutive years. He envisioned a completely redeveloped Lakewood campus, and he was instrumental in adding a new academic building, a new residence hall, and a new student center. Today, the “Armstrong Legacy” initiative is the cornerstone of CCU’s future plan. With a fund-raising effort led by Wil Armstrong, the new $30 million “Armstrong Center,” on the lake at the core of the campus will include a new worship sanctuary, library, and conference/meeting center, as well as a new auditorium and headquarters for music, theater, and performing arts. The Armstrong Legacy also includes a second new academic building, and eventually a major endowment fund for need-based scholarships.'}
        image={image}
      />
    ) : (
        <Container>
          <div className="left">
            <img src={image} />
            <div className="caption">
              Speaking at the Western Conservative Summit
            </div>
          </div >
          <div className="middle">
            He taught openness and tolerance as a cornerstone of faith, believed Christians should laugh and have fun, and said that institutions like CCU would be great if they inspired joy. That would attract more students, better faculty, and dedicated donors, because most people want to be where the fun is. The strategy worked, and CCU during his tenure achieved higher academic standards, a stronger curriculum, a campus transformed with new buildings, and a generation of young people inspired by his faith and his example. Enrollment more than doubled, and the American Council of Trustees and Alumni has now ranked CCU in the top two percent of colleges nationally for its core curriculum eight consecutive years. He envisioned a completely
    </div>
          <div className="right">
            redeveloped Lakewood campus, and he was instrumental in adding a new academic building, a new residence hall, and a new student center. Today, the “Armstrong Legacy” initiative is the cornerstone of CCU’s future plan. With a fund-raising effort led by Wil Armstrong, the new $30 million “Armstrong Center,” on the lake at the core of the campus will include a new worship sanctuary, library, and conference/meeting center, as well as a new auditorium and headquarters for music, theater, and performing arts. The Armstrong Legacy also includes a second new academic building, and eventually a major endowment fund for need-based scholarships.

    </div>
        </Container >
      )

  )
};

export default ChristianSectionTwo;